// index.js
import Typography from "../../../components/Typography";
import ScheduleForm from "../../Forms/Consultation";
import { Container, Content, FormContainer, FormHeading, IconsWrapper, IconItem, ImageBackground } from "./styled";
import authority from "../EEAT/_authority.json";
import Image from "next/image";

export default function GetStarted({ formId }) {
  return (
    <Container>
      <ImageBackground />
      <Content>
        <div>
          <FormContainer>
            <FormHeading>
              Complimentary Design Consultation & Estimate
            </FormHeading>
            <ScheduleForm formId={formId} submitText="GET STARTED" variant="small" />
            <IconsWrapper>
              {authority.map((item) => (
                <IconItem key={item.id}>
                  <Image
                    src={item.icon}
                    alt={item.title}
                    width={40}
                    height={40}
                    quality={70}
                  />
                  <span className="icon-title">{item.title}</span>
                </IconItem>
              ))}
            </IconsWrapper>
          </FormContainer>
        </div>
      </Content>
    </Container>
  );
}
